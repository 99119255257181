import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-holder">
      <footer className="footer">
        <div className="container is-max-widescreen">
          <div className="footer-grid">
            <div>
              <div className="logo-holder">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675682580/Group_7224_m0fkqj.png"
                  alt="logo"
                />
              </div>
              <div>
                <p>info@analyticsintelligence.com</p>
                <div className="icons">
                  <a
                    href="https://www.linkedin.com/company/2699628"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>

                  <a
                    href="https://twitter.com/_AnalyticsIntel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/Analyticsintel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </div>
              </div>
              <small style={{ fontSize: "1rem" }}>
                ©{new Date().getFullYear()} [AI] Analytics Intelligence Inc. All
                Rights Reserved
              </small>
            </div>

            <div>
              <h4 className="footer-title">Quick links</h4>
              <ul>
                <li>
                  <Link reloadDocument to="/">
                    Features
                  </Link>
                </li>

                {/* <li>
                  <Link reloadDocument to="/pricing">
                    Pricing
                  </Link>
                </li> */}
                <li>
                  <a
                    href="https://analyticsintelligence.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="https://myacademy.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    [AI] Academy
                  </a>
                </li>
                <li>
                  <a
                    href="https://mydata.ai/privacy-policy-and-cookies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://mydata.ai/terms-of-use/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of use
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="footer-title">Address</h4>
              <div>
                <h4 className="footer-subtitle is-underlined">United States</h4>
                <p>440 N Wolfe Rd, Sunnyvale, CA 94085, United States</p>
              </div>
              <div>
                <h4 className="footer-subtitle is-underlined">
                  United Kingdom
                </h4>
                <p>
                  One Canada Square Canary Wharf, London E14 5AB, United
                  Kingdom.
                </p>
              </div>
              <div>
                <h4 className="footer-subtitle is-underlined">Nigeria</h4>
                <p>
                  Landmark Towers, Oniru Road, Victoria Island 101241, Lagos
                  State, Nigeria
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <style jsx="true">{`
        .footer-holder {
          margin-top: auto;
        }
        .icons {
          font-size: 30px;
          color: #fff;
        }
        .icons a {
          margin-right: 10px;
          color: #fff;
          margin-top: 1rem;
          margin-bottom: 1rem;
          display: inline-block;
        }
        .logo-holder {
          margin-bottom: 2rem;
          margin-top: 3rem;
        }
        .footer {
          padding-top: 2rem;
          padding-bottom: 2rem;
          background-color: #1f3569;
          color: rgba(255, 255, 255, 0.8);
        }
        .footer-grid {
          display: flex;
          margin-bottom: 2rem;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .footer-subgrid {
          display: flex;
          margin-bottom: 2rem;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .footer-subgrid > * {
          flex-basis: 320px;
        }
        .footer-grid > * {
          flex-basis: 300px;
        }
        .footer-title {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 1.1rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #fff;
        }
        .footer-subtitle {
          font-weight: bold;
          font-size: 1rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #fff;
        }
        .footer p {
          font-size: 1rem;
        }
        .footer ul {
          list-style: none;
          padding: 0px;
        }
        .footer ul li a {
          margin-top: 1rem;
          margin-bottom: 1rem;
          display: inline-block;
          margin-right: 1rem;
        }
        .footer a {
          text-decoration: none;
          color: inherit;
        }
        .footer .twin-house {
          flex-basis: 320px;
        }
        .footer .twin {
          display: flex;
          align-items: flex-start;
        }
        .footer .twin ul {
          display: inline-block;
        }
        .footer .twin ul:last-of-type {
          margin-left: 2rem;
        }
      `}</style>
    </div>
  );
}
