import React from "react";
import ProductivityCard from "./cards/ProductivityCard";
import QuoteCard from "./cards/QuoteCard";

const Productivity = () => {
  return (
    <div className="md:px-0 px-[10px] container is-max-widescreen py-[40px] flex flex-col gap-[60px]">
      <div className="text-center flex flex-col justify-center">
        <h2 className="sm:text-[36px] text-[20px] font-[600] text-[#2F4171]">
          Productivity At Scale
        </h2>
        <p className="text-textColor sm:text-[16px] text-[13px]">
          Achieve seamless performance across your organisation
        </p>
      </div>
      <div className="grid lg:grid-cols-3 gap-[23px]">
        <ProductivityCard text="Automate repetitive tasks and focus on strategic decision-making." />
        <ProductivityCard text="Analyse company-wide data with advanced visualisations and AI-driven insights." />
        <ProductivityCard text="Collaborate effectively with a centralised platform for all your data needs." />
      </div>
      <div className="sm:w-[70%] mx-auto">
        <QuoteCard>
          <div className="text-textColor text-center flex flex-col gap-[40px] ">
            <p className="sm:text-[16px] text-[14px]">
              The Data Assistant has been a game-changer for us in how we manage
              digital analytics requests from our Digital Marketing teams. The
              speed and accuracy of the Al in responding to marketing analytics
              queries has allowed our team to focus on more deep-dive,
              qualitative analysis tasks.
            </p>
            <p className="font-[700] sm:text-[16px] text-[14px]">
              – Digital Analytics Manager, eCommerce Company 
            </p>
          </div>
        </QuoteCard>
      </div>
    </div>
  );
};

export default Productivity;
