import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { countries } from "./constant";
import toast from "react-hot-toast";

export default function ContactForm() {
  const [userCountryCode, setUserCountryCode] = useState("GB");
  const [userCountry, setUserCountry] = useState("United Kingdom");

  const [submitting, setSubmitting] = useState(false);
  const [phone, setPhone] = useState("");
  useEffect(() => {
    const fetchUserCountry = async () => {
      const request = await fetch(
        "https://ipinfo.io/json?token=9cb24768da54fe"
      );
      const jsonResponse = await request.json();
      setUserCountryCode(jsonResponse?.country || "GB");
      setUserCountry(
        countries.filter((c) => c.countryCode === jsonResponse?.country)[0]
          .value || "United Kingdom"
      );
    };

    fetchUserCountry();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const form = new FormData(e.target);
    const payload = {
      ...Object.fromEntries(form),
      phone: phone, // Include phone separately
    };

    console.log(payload);

    try {
      const response = await fetch("https://formspree.io/f/xrbgoglv", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        e.target.reset(); // Reset form fields
        setPhone(""); // Clear phone state
      } else {
        toast.error("Form submission failed. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="p-2 mx-auto lg:w-[70%]">
      <h1 className="mb-5 text-thickBlue font-[600] text-center">
        Contact Form
      </h1>
      <form
        onSubmit={handleSubmit}
        className="mt-5 grid md:grid-cols-2 gap-[30px]"
      >
        <div class="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="First name">
            First name <span className="text-[#F72525]">*</span>
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Enter first name"
              id="First name"
              required
              name="First name"
            />
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="Last name">
            Last name <span className="text-[#F72525]">*</span>
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Enter last name"
              id="Last name"
              required
              name="Last name"
            />
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="Work email">
            Work email <span className="text-[#F72525]">*</span>
          </label>
          <div class="control">
            <input
              class="input"
              type="email"
              placeholder="Enter work email"
              id="Work email"
              required
              name="Work email"
            />
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="Role">
            Role <span className="text-[#F72525]">*</span>
          </label>
          <div class="control">
            <div class="select" style={{ width: "100%" }}>
              <select id="Role" style={{ width: "100%" }} name="Role">
                <option>Select Role</option>
                <option value={"CEO"}>CEO</option>
                <option value={"VP"}>VP</option>
                <option value={"Data Analyst"}>Data Analyst</option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="Company Name">
            Company Name <span className="text-[#F72525]">*</span>
          </label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Enter company name"
              id="Company name"
              required
              name="Company name"
            />
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="Company Size">
            Company Size <span className="text-[#F72525]">*</span>
          </label>
          <div class="control">
            <div class="select" style={{ width: "100%" }}>
              <select
                id="Company size"
                style={{ width: "100%" }}
                name="Company size"
                required
              >
                <option>Select company size</option>
                <option value={"1-10"}>1-10</option>
                <option value={"11-50"}>11-50</option>
                <option value={"51-200"}>51-100</option>
                <option value={"201+"}>201+</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="Country/Region">
            Country/Region <span className="text-[#F72525]">*</span>
          </label>
          <div className="control">
            <div className="select " style={{ width: "100%" }}>
              <select
                id="Country/Region"
                style={{ width: "100%" }}
                name="Country/Region"
                value={userCountry}
                onChange={(e) => {
                  setUserCountry(e.target.value);
                  setUserCountryCode(
                    countries.filter((c) => c.value === e.target.value)[0]
                      .countryCode
                  );
                }}
              >
                {countries.map((c) => (
                  <option value={c.value}>{c.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="text-thickBlue font-[600]" htmlFor="Phone number">
            Phone number <span className="text-[#F72525]">*</span>
          </label>
          <MuiTelInput
            id="phone"
            value={phone}
            defaultCountry={userCountryCode}
            onChange={(val, details) => {
              setPhone(val);
              setUserCountryCode(details.countryCode);
              setUserCountry(
                countries.filter(
                  (c) => c.countryCode === details.countryCode
                )[0].value
              );
            }}
            className="w-full"
            placeholder="Phone number"
          />
        </div>
        <div class="flex flex-col gap-[10px] md:col-span-2">
          <label class="text-thickBlue font-[600]" htmlFor="How can we help?">
            How can we help? <span className="text-[#F72525]">*</span>
          </label>
          <div class="control">
            <div class="select" style={{ width: "100%" }}>
              <select
                id="How can we help?"
                style={{ width: "100%" }}
                name="How can we help?"
              >
                <option>Select Option</option>
                <option value={"Request pricing details"}>
                  Request pricing details
                </option>
                <option value={"Learn about advanced features"}>
                  Learn about advanced features
                </option>
                <option value={"Explore industry-specific use cases"}>
                  Explore industry-specific use cases
                </option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-[10px] md:col-span-2 ">
          <label
            class="text-thickBlue font-[600]"
            htmlFor="Additional Information"
          >
            Anything else? (optional)
          </label>
          <div class="control">
            <textarea
              class="input min-h-[150px]"
              type="text"
              placeholder="Tell us about your data challenges or how Data Assistant can help"
              id="Additional Information"
              name="Additional Information"
            />
          </div>
        </div>
        <div className="md:col-span-2 flex flex-col gap-[10px]">
          <span className="text-[#F72525] sm:text-[14px] text-[12px]">
            Fields marked with an asterisk (*) are required.
          </span>
          <div className="flex gap-[10px]">
            <input
              type="checkbox"
              name="subscribe"
              id="subscribe"
              className="h-[30px] w-[30px]"
            />
            <label
              className="sm:text-[14px] text-[12px] text-textColor font-[500]"
              htmlFor="subscribe"
            >
              Yes, I would like to receive marketing communications about the
              Data Assistant family of companies' products, services, and
              events. I can unsubscribe at any time. 
            </label>
          </div>
        </div>
        <div className="md:col-span-2 flex justify-center">
          <button
            type="submit"
            role={"form"}
            disabled={submitting}
            className="spread-button orange-bg mb-5 sm:w-[40%] w-full"
            style={{
              color: "#fff",
              display: "inline-block",
            }}
          >
            {submitting ? "Please wait..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
