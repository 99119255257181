import React from "react";
import BenefitCard from "./cards/BenefitCard";
import OneIcon from "../../icons/oneIcon";
import TwoIcon from "../../icons/twoIcon";
import ThreeIcon from "../../icons/threeIcon";
import ContactForm from "./ContactForm";

const SalesPage = () => {
  return (
    <main className="container is-max-widescreen md:px-[0px] px-[30px] sm:pt-[180px] pt-[80px] pb-[160px] flex flex-col sm:gap-[120px] gap-[60px]">
      <div className="flex flex-col gap-[40px]">
        <div className="flex flex-col gap-[10px]">
          <h1 className="font-[600] sm:text-[48px] text-[24px] text-[#2F4171] text-center">
            Talk to Sales
          </h1>
          <p className="sm:text-[16px] text-[14px] font-[300] text-textColor text-center">
            We’re happy to answer your questions and introduce you to the full
            capabilities of Data Assistant. 
          </p>
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1732871236/Hero_Image_dxs5nf.png"
            alt="illustration"
            className="min-h-[148px]"
          />
        </div>
        <div className="flex flex-col gap-[20px]">
          <div className="grid lg:grid-cols-3 gap-[23px] ">
            <BenefitCard>
              <OneIcon />
              <p className="text-textColor sm:text-[16px] text-[14px] w-[80%]">
                Get personalised pricing details tailored to your needs. 
              </p>
            </BenefitCard>
            <BenefitCard>
              <TwoIcon />
              <p className="text-textColor sm:text-[16px] text-[14px] w-[80%]">
                Learn how to enhance decision-making through advanced analytics.
              </p>
            </BenefitCard>
            <BenefitCard>
              <ThreeIcon />
              <p className="text-textColor sm:text-[16px] text-[14px] w-[80%]">
                Explore practical use cases for your team or organisation. 
              </p>
            </BenefitCard>
          </div>
          <p className="text-textColor sm:text-[16px] text-[14px] text-center">
            For technical issues or product-related queries, please contact
            enquiry@analyticsintelligence.com. 
          </p>
        </div>
      </div>
      <ContactForm/>
    </main>
  );
};

export default SalesPage;
