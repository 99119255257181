import React from "react";

const DropDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="13"
      viewBox="0 0 24 13"
      fill="none"
    >
      <g clip-path="url(#clip0_3201_2695)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.2891 10.657L5.6321 5L7.04611 3.586L11.9961 8.536L16.9461 3.586L18.3601 5L12.7031 10.657C12.5156 10.8445 12.2613 10.9498 11.9961 10.9498C11.7309 10.9498 11.4766 10.8445 11.2891 10.657Z"
          fill="black"
          fill-opacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_3201_2695">
          <rect
            width="12"
            height="24"
            fill="white"
            transform="translate(24 0.5) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DropDownIcon;
