import React from 'react'

const QuoteCard = ({children}) => {
  return (
    <div className='flex sm:gap-[30px] gap-[10px] items-start'>
      <img src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734008932/raphael_quote_zjxhvk.svg" alt="quote" />
        {children}
      <img src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734012649/raphael_quote-l_sonffd.svg" alt="" />
    </div>
  )
}

export default QuoteCard
