import Header from "./components/header";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/footer";
import Home from "./components/home";
import Faqs from "./components/faq";
import Form from "./components/form";
import Feature from "./components/feature";
import Chatbot from "./components/chatBot";
import { Toaster } from "react-hot-toast";
import PricingPage from "./components/pricing";
import SalesPage from "./components/sales";
import EnterprisePage from "./components/enterprise";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        {/* <Route path="/pricing" element={<Faqs />} /> */}
        <Route path="/demo" element={<Form />} />
        <Route path="/feature" element={<Feature />} />
        {/* <Route path="/pricing" element={<PricingPage />} /> */}
        <Route path="/sales" element={<SalesPage />} />
        <Route path="/enterprise" element={<EnterprisePage />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Chatbot />
      <Toaster
        toastOptions={{
          style: {
            maxWidth: "700px",
            padding: "12px 16px",
            fontSize: "17px",
            fontWeight: "400",
          },
          error: {
            style: {
              color: "red",
            },
          },
          success: {
            style: {
              color: "green",
            },
          },
        }}
        position="top-center"
        reverseOrder={false}
      />
      <Footer />
      <style jsx="">{`
        .App {
          display: flex;
          flex-direction: column;
          min-height: 100vh;
        }
      `}</style>
    </div>
  );
}

export default App;
