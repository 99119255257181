import React from "react";

const TwoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M14 8H30C31.0609 8 32.0783 8.42143 32.8284 9.17157C33.5786 9.92172 34 10.9391 34 12V22C34 23.0609 33.5786 24.0783 32.8284 24.8284C32.0783 25.5786 31.0609 26 30 26H18V36H34V40H14V26C14 24.9391 14.4214 23.9217 15.1716 23.1716C15.9217 22.4214 16.9391 22 18 22H30V12H14V8Z"
        fill="#1A8AFF"
      />
    </svg>
  );
};

export default TwoIcon;
