import React from "react";
import QuoteCard from "./cards/QuoteCard";

const Security = () => {
  return (
    <div className="bg-[#F4F9FF] py-[80px]">
      <div className="grid lg:grid-cols-2 gap-[41px] items-center md:px-0 px-[10px] container is-max-widescreen">
        <div>
          <h2 className="text-[#2F4171] sm:text-[36px] text-[20px] text-center lg:text-start font-[600]">
            Security And Compliance
          </h2>
          <div className="flex flex-col gap-[10px] mt-[30px] sm:!text-[16px] !text-[13px]">
            <div className="flex item-center gap-[20px]">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734014453/mdi_security-lock-outline_dxbcmh.svg"
                alt="security"
              />
              <p className="text-textColor sm:!text-[16px] !text-[13px]">
                Meet enterprise-grade compliance standards, including ISO 27001,
                GDPR, and more.
              </p>
            </div>
            <div className="flex item-center gap-[20px]">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734014453/mdi_security-lock_iedaae.svg"
                alt="security"
              />
              <p className="text-textColor sm:!text-[16px] !text-[13px]">
                Protect sensitive information with built-in data encryption and
                access controls.
              </p>
            </div>
            <div className="flex item-center gap-[20px]">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734014453/mdi_security-lock-outline_dxbcmh.svg"
                alt="security"
              />
              <p className="text-textColor sm:!text-[16px] !text-[13px]">
                Gain full control of your data with customisable security
                settings.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[40px]">
          <img
            src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734008933/key_idwqzu.png"
            alt=""
          />
          <QuoteCard>
            <div className="text-textColor text-center flex flex-col gap-[40px]">
              <p className="sm:text-[16px] text-[14px]">
                I'm able to track KPIs in real-time, allowing me to make
                data-driven decisions on the fly. The Al has been a game-changer
                for our marketing efforts.
              </p>
              <p className="font-[700] sm:text-[16px] text-[14px]">
                – Marketing Specialist, Parcel Delivery Company
              </p>
            </div>
          </QuoteCard>
        </div>
      </div>
    </div>
  );
};

export default Security;
