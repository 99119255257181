import React from 'react'

const BenefitCard = ({children}) => {
  return (
    <div className='rounded-[10px] px-[32px] py-[27px] bg-[#F4F9FF] flex gap-[10px] '>
      {children}
    </div>
  )
}

export default BenefitCard
