export const countries = [
  { value: "Afghanistan", label: "Afghanistan", countryCode: "AF" },
  { value: "Åland Islands", label: "Åland Islands", countryCode: "AX" },
  { value: "Albania", label: "Albania", countryCode: "AL" },
  { value: "Algeria", label: "Algeria", countryCode: "DZ" },
  { value: "American Samoa", label: "American Samoa", countryCode: "AS" },
  { value: "Andorra", label: "Andorra", countryCode: "AD" },
  { value: "Angola", label: "Angola", countryCode: "AO" },
  { value: "Anguilla", label: "Anguilla", countryCode: "AI" },
  { value: "Antarctica", label: "Antarctica", countryCode: "AQ" },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
    countryCode: "AG",
  },
  { value: "Argentina", label: "Argentina", countryCode: "AR" },
  { value: "Armenia", label: "Armenia", countryCode: "AM" },
  { value: "Aruba", label: "Aruba", countryCode: "AW" },
  { value: "Australia", label: "Australia", countryCode: "AU" },
  { value: "Austria", label: "Austria", countryCode: "AT" },
  { value: "Azerbaijan", label: "Azerbaijan", countryCode: "AZ" },
  { value: "Bahamas", label: "Bahamas", countryCode: "BS" },
  { value: "Bahrain", label: "Bahrain", countryCode: "BH" },
  { value: "Bangladesh", label: "Bangladesh", countryCode: "BD" },
  { value: "Barbados", label: "Barbados", countryCode: "BB" },
  { value: "Belarus", label: "Belarus", countryCode: "BY" },
  { value: "Belgium", label: "Belgium", countryCode: "BE" },
  { value: "Belize", label: "Belize", countryCode: "BZ" },
  { value: "Benin", label: "Benin", countryCode: "BJ" },
  { value: "Bermuda", label: "Bermuda", countryCode: "BM" },
  { value: "Bhutan", label: "Bhutan", countryCode: "BT" },
  { value: "Bolivia", label: "Bolivia", countryCode: "BO" },
  {
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
    countryCode: "BA",
  },
  { value: "Botswana", label: "Botswana", countryCode: "BW" },
  { value: "Bouvet Island", label: "Bouvet Island", countryCode: "BV" },
  { value: "Brazil", label: "Brazil", countryCode: "BR" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
    countryCode: "IO",
  },
  { value: "Brunei Darussalam", label: "Brunei Darussalam", countryCode: "BN" },
  { value: "Bulgaria", label: "Bulgaria", countryCode: "BG" },
  { value: "Burkina Faso", label: "Burkina Faso", countryCode: "BF" },
  { value: "Burundi", label: "Burundi", countryCode: "BI" },
  { value: "Cambodia", label: "Cambodia", countryCode: "KH" },
  { value: "Cameroon", label: "Cameroon", countryCode: "CM" },
  { value: "Canada", label: "Canada", countryCode: "CA" },
  { value: "Cape Verde", label: "Cape Verde", countryCode: "CV" },
  { value: "Cayman Islands", label: "Cayman Islands", countryCode: "KY" },
  {
    value: "Central African Republic",
    label: "Central African Republic",
    countryCode: "CF",
  },
  { value: "Chad", label: "Chad", countryCode: "TD" },
  { value: "Chile", label: "Chile", countryCode: "CL" },
  { value: "China", label: "China", countryCode: "CN" },
  { value: "Christmas Island", label: "Christmas Island", countryCode: "CX" },
  {
    value: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
    countryCode: "CC",
  },
  { value: "Colombia", label: "Colombia", countryCode: "CO" },
  { value: "Comoros", label: "Comoros", countryCode: "KM" },
  { value: "Congo", label: "Congo", countryCode: "CG" },
  {
    value: "Congo, The Democratic Republic of The",
    label: "Congo, The Democratic Republic of The",
    countryCode: "CD",
  },
  { value: "Cook Islands", label: "Cook Islands", countryCode: "CK" },
  { value: "Costa Rica", label: "Costa Rica", countryCode: "CR" },
  { value: "Cote D'ivoire", label: "Cote D'ivoire", countryCode: "CI" },
  { value: "Croatia", label: "Croatia", countryCode: "HR" },
  { value: "Cuba", label: "Cuba", countryCode: "CU" },
  { value: "Cyprus", label: "Cyprus", countryCode: "CY" },
  { value: "Czech Republic", label: "Czech Republic", countryCode: "CZ" },
  { value: "Denmark", label: "Denmark", countryCode: "DK" },
  { value: "Djibouti", label: "Djibouti", countryCode: "DJ" },
  { value: "Dominica", label: "Dominica", countryCode: "DM" },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
    countryCode: "DO",
  },
  { value: "Ecuador", label: "Ecuador", countryCode: "EC" },
  { value: "Egypt", label: "Egypt", countryCode: "EG" },
  { value: "El Salvador", label: "El Salvador", countryCode: "SV" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea", countryCode: "GQ" },
  { value: "Eritrea", label: "Eritrea", countryCode: "ER" },
  { value: "Estonia", label: "Estonia", countryCode: "EE" },
  { value: "Ethiopia", label: "Ethiopia", countryCode: "ET" },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
    countryCode: "FK",
  },
  { value: "Faroe Islands", label: "Faroe Islands", countryCode: "FO" },
  { value: "Fiji", label: "Fiji", countryCode: "FJ" },
  { value: "Finland", label: "Finland", countryCode: "FI" },
  { value: "France", label: "France", countryCode: "FR" },
  { value: "French Guiana", label: "French Guiana", countryCode: "GF" },
  { value: "French Polynesia", label: "French Polynesia", countryCode: "PF" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
    countryCode: "TF",
  },
  { value: "Gabon", label: "Gabon", countryCode: "GA" },
  { value: "Gambia", label: "Gambia", countryCode: "GM" },
  { value: "Georgia", label: "Georgia", countryCode: "GE" },
  { value: "Germany", label: "Germany", countryCode: "DE" },
  { value: "Ghana", label: "Ghana", countryCode: "GH" },
  { value: "Gibraltar", label: "Gibraltar", countryCode: "GI" },
  { value: "Greece", label: "Greece", countryCode: "GR" },
  { value: "Greenland", label: "Greenland", countryCode: "GL" },
  { value: "Grenada", label: "Grenada", countryCode: "GD" },
  { value: "Guadeloupe", label: "Guadeloupe", countryCode: "GP" },
  { value: "Guam", label: "Guam", countryCode: "GU" },
  { value: "Guatemala", label: "Guatemala", countryCode: "GT" },
  { value: "Guernsey", label: "Guernsey", countryCode: "GG" },
  { value: "Guinea", label: "Guinea", countryCode: "GN" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau", countryCode: "GW" },
  { value: "Guyana", label: "Guyana", countryCode: "GY" },
  { value: "Haiti", label: "Haiti", countryCode: "HT" },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
    countryCode: "HM",
  },
  {
    value: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
    countryCode: "VA",
  },
  { value: "Honduras", label: "Honduras", countryCode: "HN" },
  { value: "Hong Kong", label: "Hong Kong", countryCode: "HK" },
  { value: "Hungary", label: "Hungary", countryCode: "HU" },
  { value: "Iceland", label: "Iceland", countryCode: "IS" },
  { value: "India", label: "India", countryCode: "IN" },
  { value: "Indonesia", label: "Indonesia", countryCode: "ID" },
  {
    value: "Iran, Islamic Republic of",
    label: "Iran, Islamic Republic of",
    countryCode: "IR",
  },
  { value: "Iraq", label: "Iraq", countryCode: "IQ" },
  { value: "Ireland", label: "Ireland", countryCode: "IE" },
  { value: "Isle of Man", label: "Isle of Man", countryCode: "IM" },
  { value: "Israel", label: "Israel", countryCode: "IL" },
  { value: "Italy", label: "Italy", countryCode: "IT" },
  { value: "Jamaica", label: "Jamaica", countryCode: "JM" },
  { value: "Japan", label: "Japan", countryCode: "JP" },
  { value: "Jersey", label: "Jersey", countryCode: "JE" },
  { value: "Jordan", label: "Jordan", countryCode: "JO" },
  { value: "Kazakhstan", label: "Kazakhstan", countryCode: "KZ" },
  { value: "Kenya", label: "Kenya", countryCode: "KE" },
  { value: "Kiribati", label: "Kiribati", countryCode: "KI" },
  {
    value: "Korea, Democratic People's Republic of",
    label: "Korea, Democratic People's Republic of",
    countryCode: "KP",
  },
  {
    value: "Korea, Republic of",
    label: "Korea, Republic of",
    countryCode: "KR",
  },
  { value: "Kuwait", label: "Kuwait", countryCode: "KW" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan", countryCode: "KG" },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
    countryCode: "LA",
  },
  { value: "Latvia", label: "Latvia", countryCode: "LV" },
  { value: "Lebanon", label: "Lebanon", countryCode: "LB" },
  { value: "Lesotho", label: "Lesotho", countryCode: "LS" },
  { value: "Liberia", label: "Liberia", countryCode: "LR" },
  { value: "Libya", label: "Libya", countryCode: "LY" },
  { value: "Liechtenstein", label: "Liechtenstein", countryCode: "LI" },
  { value: "Lithuania", label: "Lithuania", countryCode: "LT" },
  { value: "Luxembourg", label: "Luxembourg", countryCode: "LU" },
  { value: "Macao", label: "Macao", countryCode: "MO" },
  {
    value: "Macedonia, The Former Yugoslav Republic of",
    label: "Macedonia, The Former Yugoslav Republic of",
    countryCode: "MK",
  },
  { value: "Madagascar", label: "Madagascar", countryCode: "MG" },
  { value: "Malawi", label: "Malawi", countryCode: "MW" },
  { value: "Malaysia", label: "Malaysia", countryCode: "MY" },
  { value: "Maldives", label: "Maldives", countryCode: "MV" },
  { value: "Mali", label: "Mali", countryCode: "ML" },
  { value: "Malta", label: "Malta", countryCode: "MT" },
  { value: "Marshall Islands", label: "Marshall Islands", countryCode: "MH" },
  { value: "Martinique", label: "Martinique", countryCode: "MQ" },
  { value: "Mauritania", label: "Mauritania", countryCode: "MR" },
  { value: "Mauritius", label: "Mauritius", countryCode: "MU" },
  { value: "Mayotte", label: "Mayotte", countryCode: "YT" },
  { value: "Mexico", label: "Mexico", countryCode: "MX" },
  {
    value: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
    countryCode: "FM",
  },
  {
    value: "Moldova, Republic of",
    label: "Moldova, Republic of",
    countryCode: "MD",
  },
  { value: "Monaco", label: "Monaco", countryCode: "MC" },
  { value: "Mongolia", label: "Mongolia", countryCode: "MN" },
  { value: "Montenegro", label: "Montenegro", countryCode: "ME" },
  { value: "Montserrat", label: "Montserrat", countryCode: "MS" },
  { value: "Morocco", label: "Morocco", countryCode: "MA" },
  { value: "Mozambique", label: "Mozambique", countryCode: "MZ" },
  { value: "Myanmar", label: "Myanmar", countryCode: "MM" },
  { value: "Namibia", label: "Namibia", countryCode: "NA" },
  { value: "Nauru", label: "Nauru", countryCode: "NR" },
  { value: "Nepal", label: "Nepal", countryCode: "NP" },
  { value: "Netherlands", label: "Netherlands", countryCode: "NL" },
  { value: "New Caledonia", label: "New Caledonia", countryCode: "NC" },
  { value: "New Zealand", label: "New Zealand", countryCode: "NZ" },
  { value: "Nicaragua", label: "Nicaragua", countryCode: "NI" },
  { value: "Niger", label: "Niger", countryCode: "NE" },
  { value: "Nigeria", label: "Nigeria", countryCode: "NG" },
  { value: "Niue", label: "Niue", countryCode: "NU" },
  { value: "Norfolk Island", label: "Norfolk Island", countryCode: "NF" },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
    countryCode: "MP",
  },
  { value: "Norway", label: "Norway", countryCode: "NO" },
  { value: "Oman", label: "Oman", countryCode: "OM" },
  { value: "Pakistan", label: "Pakistan", countryCode: "PK" },
  { value: "Palau", label: "Palau", countryCode: "PW" },
  {
    value: "Palestine, State of",
    label: "Palestine, State of",
    countryCode: "PS",
  },
  { value: "Panama", label: "Panama", countryCode: "PA" },
  { value: "Papua New Guinea", label: "Papua New Guinea", countryCode: "PG" },
  { value: "Paraguay", label: "Paraguay", countryCode: "PY" },
  { value: "Peru", label: "Peru", countryCode: "PE" },
  { value: "Philippines", label: "Philippines", countryCode: "PH" },
  { value: "Pitcairn", label: "Pitcairn", countryCode: "PN" },
  { value: "Poland", label: "Poland", countryCode: "PL" },
  { value: "Portugal", label: "Portugal", countryCode: "PT" },
  { value: "Puerto Rico", label: "Puerto Rico", countryCode: "PR" },
  { value: "Qatar", label: "Qatar", countryCode: "QA" },
  { value: "Réunion", label: "Réunion", countryCode: "RE" },
  { value: "Romania", label: "Romania", countryCode: "RO" },
  {
    value: "Russian Federation",
    label: "Russian Federation",
    countryCode: "RU",
  },
  { value: "Rwanda", label: "Rwanda", countryCode: "RW" },
  { value: "Saint Barthélemy", label: "Saint Barthélemy", countryCode: "BL" },
  {
    value: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
    countryCode: "SH",
  },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
    countryCode: "KN",
  },
  { value: "Saint Lucia", label: "Saint Lucia", countryCode: "LC" },
  {
    value: "Saint Martin (French part)",
    label: "Saint Martin (French part)",
    countryCode: "MF",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
    countryCode: "PM",
  },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
    countryCode: "VC",
  },
  { value: "Samoa", label: "Samoa", countryCode: "WS" },
  { value: "San Marino", label: "San Marino", countryCode: "SM" },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
    countryCode: "ST",
  },
  { value: "Saudi Arabia", label: "Saudi Arabia", countryCode: "SA" },
  { value: "Senegal", label: "Senegal", countryCode: "SN" },
  { value: "Serbia", label: "Serbia", countryCode: "RS" },
  { value: "Seychelles", label: "Seychelles", countryCode: "SC" },
  { value: "Sierra Leone", label: "Sierra Leone", countryCode: "SL" },
  { value: "Singapore", label: "Singapore", countryCode: "SG" },
  {
    value: "Sint Maarten (Dutch part)",
    label: "Sint Maarten (Dutch part)",
    countryCode: "SX",
  },
  { value: "Slovakia", label: "Slovakia", countryCode: "SK" },
  { value: "Slovenia", label: "Slovenia", countryCode: "SI" },
  { value: "Solomon Islands", label: "Solomon Islands", countryCode: "SB" },
  { value: "Somalia", label: "Somalia", countryCode: "SO" },
  { value: "South Africa", label: "South Africa", countryCode: "ZA" },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
    countryCode: "GS",
  },
  { value: "South Sudan", label: "South Sudan", countryCode: "SS" },
  { value: "Spain", label: "Spain", countryCode: "ES" },
  { value: "Sri Lanka", label: "Sri Lanka", countryCode: "LK" },
  { value: "Sudan", label: "Sudan", countryCode: "SD" },
  { value: "Suriname", label: "Suriname", countryCode: "SR" },
  {
    value: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
    countryCode: "SJ",
  },
  { value: "Swaziland", label: "Swaziland", countryCode: "SZ" },
  { value: "Sweden", label: "Sweden", countryCode: "SE" },
  { value: "Switzerland", label: "Switzerland", countryCode: "CH" },
  {
    value: "Syrian Arab Republic",
    label: "Syrian Arab Republic",
    countryCode: "SY",
  },
  {
    value: "Taiwan, Province of China",
    label: "Taiwan, Province of China",
    countryCode: "TW",
  },
  { value: "Tajikistan", label: "Tajikistan", countryCode: "TJ" },
  {
    value: "Tanzania, United Republic of",
    label: "Tanzania, United Republic of",
    countryCode: "TZ",
  },
  { value: "Thailand", label: "Thailand", countryCode: "TH" },
  { value: "Timor-Leste", label: "Timor-Leste", countryCode: "TL" },
  { value: "Togo", label: "Togo", countryCode: "TG" },
  { value: "Tokelau", label: "Tokelau", countryCode: "TK" },
  { value: "Tonga", label: "Tonga", countryCode: "TO" },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
    countryCode: "TT",
  },
  { value: "Tunisia", label: "Tunisia", countryCode: "TN" },
  { value: "Turkey", label: "Turkey", countryCode: "TR" },
  { value: "Turkmenistan", label: "Turkmenistan", countryCode: "TM" },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  { value: "Tuvalu", label: "Tuvalu", countryCode: "TV" },
  { value: "Uganda", label: "Uganda", countryCode: "UG" },
  { value: "Ukraine", label: "Ukraine", countryCode: "UA" },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
    countryCode: "AE",
  },
  { value: "United Kingdom", label: "United Kingdom", countryCode: "GB" },
  { value: "United States", label: "United States", countryCode: "US" },
  { value: "Uruguay", label: "Uruguay", countryCode: "UY" },
  { value: "Uzbekistan", label: "Uzbekistan", countryCode: "UZ" },
  { value: "Vanuatu", label: "Vanuatu", countryCode: "VU" },
  {
    value: "Venezuela, Bolivarian Republic of",
    label: "Venezuela, Bolivarian Republic of",
    countryCode: "VE",
  },
  { value: "Viet Nam", label: "Viet Nam", countryCode: "VN" },
  { value: "Western Sahara", label: "Western Sahara", countryCode: "EH" },
  { value: "Yemen", label: "Yemen", countryCode: "YE" },
  { value: "Zambia", label: "Zambia", countryCode: "ZM" },
  { value: "Zimbabwe", label: "Zimbabwe", countryCode: "ZW" },
];
