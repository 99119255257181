import React from 'react'

const ProductivityCard = ({text, imageClassName, className}) => {
  return (
    <div className={`rounded-[10px] bg-[#F4F9FF] pr-[19px] ${className} flex gap-[12px] items-center overflow-hidden`}>
      <img className={imageClassName} src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734008932/Arrow_Splash_trpjtr.svg" alt="arrow" />
      <div>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default ProductivityCard
