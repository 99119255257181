import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import DropDownIcon from "../icons/dropDownIcon";

export default function Header() {
  const [isActive, setIsActive] = useState(false);
  return (
    <nav className="navbar  is-white is-fixed-top">
      <div className="container is-max-widescreen">
        <div className="navbar-brand">
          <NavLink className="navbar-item hue" to="/">
            <img
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1675682511/Group_90_q6az8a.png"
              alt="[AI] Academy"
              style={{
                objectFit: "cover",
                width: "170px",
                maxHeight: "70px",
                marginTop: "10px",
              }}
            />
          </NavLink>
          <div
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            onClick={() => setIsActive((s) => !s)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
          <div className="navbar-start"></div>
          <div className="navbar-end">
            <NavLink className="navbar-item hue" to="/feature" reloadDocument>
              Features
            </NavLink>
            <div className="relative group">
              <button
                type="button"
                className=" navbar-item hue flex gap-[5px] h-full text-[#0a0a0a]"
              >
                Plans & Services
                <DropDownIcon />
              </button>
              <div className="hidden lg:absolute top-12 right-0  bg-dark text-textColor  group-hover:flex flex-col justify-center items-center gap-4 w-[253px] rounded-lg lg:shadow-2xl lg:z-[100]  border-poppingBlue  ease-in-out p-3 bg-white text-[#0a0a0a]">
                <Link
                  to={`/enterprise`}
                  className="w-full p-2 lg:text-center flex item-center lg:justify-center leading-[24px] navbar-item hue"
                >
                  Enterprise
                </Link>
                {/* <Link
                  to={`/pricing?id=2`}
                  className="w-full p-2 lg:text-center flex item-center lg:justify-center leading-[24px] navbar-item hue"
                >
                  Pricing
                </Link> */}
                <Link
                  to={`/sales`}
                  className="w-full p-2 lg:text-center flex item-center lg:justify-center leading-[24px] navbar-item hue"
                >
                  Sales
                </Link>
              </div>
            </div>
            <a
              href="https://v3.dataassistant.ai/"
              target={"_blank"}
              rel="noreferrer"
              className="navbar-item"
            >
              Login
            </a>
            <a href="/demo" className="navbar-item hue">
              <button
                className="spread-button orange-bg"
                style={{
                  color: "#fff",
                }}
              >
                Request a demo
              </button>
            </a>
          </div>
        </div>
      </div>
      <style jsx="true">
        {`
          .navbar-brand {
            align-items: center;
          }

          .iconic {
            display: flex;
            align-items: center;
          }
          .initials {
            background-color: rgb(254, 254, 254);
            color: var(--main-hue);
            margin-right: 0.5rem;
            width: 40px;
            text-align: center;
            border-radius: 50%;
            line-height: 40px;
            font-weight: 800;
          }

          @media screen and (min-width: 1024px) {
            .navbar.is-white .navbar-end .navbar-link::after,
            .navbar.is-white .navbar-start .navbar-link::after {
              border-color: var(--main-orange);
            }
            .navbar.is-white .navbar-end .navbar-link.is-active,
            .navbar.is-white .navbar-end .navbar-link:focus,
            .navbar.is-white .navbar-end .navbar-link:hover,
            .navbar.is-white .navbar-end > a.navbar-item.is-active,
            .navbar.is-white .navbar-end > a.navbar-item:focus,
            .navbar.is-white .navbar-end > a.navbar-item:hover,
            .navbar.is-white .navbar-start .navbar-link.is-active,
            .navbar.is-white .navbar-start .navbar-link:focus,
            .navbar.is-white .navbar-start .navbar-link:hover,
            .navbar.is-white .navbar-start > a.navbar-item.is-active,
            .navbar.is-white .navbar-start > a.navbar-item:focus,
            .navbar.is-white .navbar-start > a.navbar-item:hover {
              background-color: inherit;
              color: var(--main-orange);
            }
            .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link,
            .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link,
            .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link {
              background-color: inherit;
              color: var(--main-orange);
            }
            .navbar-dropdown {
              border: 0px;
            }
            .navbar.is-white .navbar-brand .navbar-link.is-active,
            .navbar.is-white .navbar-brand .navbar-link:focus,
            .navbar.is-white .navbar-brand .navbar-link:hover,
            .navbar.is-white .navbar-brand > a.navbar-item.is-active,
            .navbar.is-white .navbar-brand > a.navbar-item:focus,
            .navbar.is-white .navbar-brand > a.navbar-item:hover {
              background-color: inherit;
              color: #0a0a0a;
            }
          }
        `}
      </style>
    </nav>
  );
}
