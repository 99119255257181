import React from "react";
import { useNavigate } from "react-router-dom";

const Hero = () => {
    const navigate = useNavigate()
  return (
    <div className="lg:min-h-[150vh] min-h-screen relative pt-[250px] md:bg-enterprise_hero_bg bg-enterprise_hero_bg_small bg-cover flex flex-col items-center">
      <div className="flex flex-col items-center gap-[40px] md:px-0 px-[10px] container is-max-widescreen">
        <h1 className="lg:text-[52px] md:text-[38px] text-[24px] font-[600] text-[#fff] text-center">
          Drive your business growth with enterprise-level insights and
          automation 
        </h1>
        <div className="flex gap-[40px]">
          <button
            className="spread-button"
            onClick={() => {
              navigate("/sales");
            }}
          >
            Talk to Sales
          </button>
          <button
            className="spread-button-alt text-primary border border-primary"
          >
            Watch Demo
          </button>
        </div>
      </div>
      <img className="absolute bottom-0 md:block hidden" src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734381240/Design_Line3_jmu4s9.png" alt="line" />
      <img className="absolute bottom-[20px] md:hidden block w-full" src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734381205/Accent_Line_edxfw4.png" alt="line" />
    </div>
  );
};

export default Hero;
