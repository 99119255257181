import React from "react";

const OneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M30 36H26V12C26 10.9391 25.5786 9.92172 24.8284 9.17157C24.0783 8.42143 23.0609 8 22 8H18V12H22V36H18V40H30V36Z"
        fill="#1A8AFF"
      />
    </svg>
  );
};

export default OneIcon;
