import React from "react";

const ThreeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M14 8H30C31.0609 8 32.0783 8.42143 32.8284 9.17157C33.5786 9.92172 34 10.9391 34 12V36C34 37.0609 33.5786 38.0783 32.8284 38.8284C32.0783 39.5786 31.0609 40 30 40H14V36H30V26H16V22H30V12H14V8Z"
        fill="#1A8AFF"
      />
    </svg>
  );
};

export default ThreeIcon;
