import React from "react";
import ProductivityCard from "./cards/ProductivityCard";
import QuoteCard from "./cards/QuoteCard";
import { useNavigate } from "react-router-dom";

const Customisation = () => {
  const navigate = useNavigate()
  return (
    <div className="sm:px-0 px-[10px] container is-max-widescreen py-[40px] flex flex-col gap-[60px]">
      <div className="text-center flex flex-col justify-center">
        <h2 className="sm:text-[36px] text-[20px] font-[600] text-[#2F4171]">
          Customisation and Integration
        </h2>
        <p className="text-textColor sm:text-[16px] text-[14px]">Tailored to fit your unique needs</p>
      </div>
      <div className="grid lg:grid-cols-3 gap-[23px]">
        <ProductivityCard
          imageClassName="scale-[1.5]"
          text="Integrate seamlessly with your existing tools and platforms."
        />
        <ProductivityCard
          imageClassName="scale-[2.5]"
          text="Simplify complex processes with intuitive dashboards and user-friendly interfaces."
        />
        <ProductivityCard
          className="!pr-0 pl-[19px] flex-row-reverse"
          imageClassName="transform scale-x-[-1] scale-[1.5]"
          text="Build custom workflows and automations for enhanced productivity."
        />
      </div>
      <div className="flex flex-col gap-[30px] items-center">
        <h2 className="font-[700] sm:text-[24px] text-[16px] text-[#2F4171]">
          Ready to <span className="text-primary">scale</span> your data
          operations?
        </h2>
        <button
            className="spread-button-2F4171 w-full sm:w-auto"
            onClick={() => {
              navigate("/sales");
            }}
          >
            Talk to Sales
          </button>
      </div>
    </div>
  );
};

export default Customisation;
