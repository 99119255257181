import React from 'react'
import Hero from './components/Hero'
import Productivity from './components/Productivity'
import Security from './components/Security'
import Customisation from './components/Customisation'
import Features from './components/Features'

const EnterprisePage = () => {
  return (
    <div>
      <Hero/>
      <Productivity/>
      <Security/>
      <Customisation/>
      <Features/>
    </div>
  )
}

export default EnterprisePage
