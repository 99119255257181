import React from "react";
import { useNavigate } from "react-router-dom";

const Features = () => {
    const navigate = useNavigate()
  return (
    <div className="bg-[#F4F9FF] relative">
      <div className="py-[90px] flex flex-col gap-[60px] container sm:px-[100px] px-[30px] is-max-widescreen">
        <h2 className="text-[#2F4171] sm:text-[36px] text-[20px] font-[600] items-center justify-center text-center">
          Enterprise-Level Features
        </h2>
        <div className="grid md:grid-cols-2 md:gap-[100px] gap-2">
          <ul className="list-disc flex flex-col gap-2">
            <li>
              <span className="font-bold">Advanced Reporting:</span>Deliver
              comprehensive insights tailored to business needs
            </li>
            <li>
              <span className="font-bold">Predictive Analytics:</span>Make future-focused decisions with AI-powered forecasts.
            </li>
            <li>
              <span className="font-bold">Integration:</span>Connect any data source via APIs, webhooks and CSV files.
            </li>
          </ul>
          <ul className="list-disc flex flex-col gap-2">
            <li>
              <span className="font-bold">Document Parsing:</span>Simplify document uploads and extract actionable data.
            </li>
            <li>
              <span className="font-bold">Interactive Chat Interface:</span>Collaborate in real time for faster resolutions.
            </li>
            <li>
              <span className="font-bold">Custom Assistants:</span>Switch between assistants tailored to your industry.
            </li>
          </ul>
        </div>
        <div className="flex flex-col sm:flex-row sm:gap-[40px] gap-[20px] justify-center relative z-10">
          <button
            className="spread-button"
            onClick={() => {
              navigate("/sales");
            }}
          >
            Talk to Sales
          </button>
          <button
            className="spread-button-alt2 text-[#2F4171] border border-[#2F4171]"
            onClick={() => {
                navigate("/demo");
              }}
          >
            Request a Demo
          </button>
        </div>
      </div>
      <img className="absolute bottom-0 md:block hidden" src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734380640/Design_Line2_otonsh.png" alt="line" />

    </div>
  );
};

export default Features;
